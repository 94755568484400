<template>
<div class="d-flex align-items-center min-vh-100 logincontainer" style="background-image: url(img/blue.svg);">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-7 col-xl-5 ">
                <div class="container-wrapper">
                    <div class="row">

                        <div class="col  text-center">
                            <h1>Login</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col ">
                            <label class="form-label">Email</label>
                            <input type="text" v-model="user" class="form-control" placehoder="E-Mail" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col ">
                            <label class="form-label">Password</label>
                            <input type="password" v-model="pwd" class="form-control" placehoder="Password" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col pt-3 ">
                            <button class="btn btn-primary w-100" @click="login">
                                Login
                            </button>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col pt-3 " v-if="errormsg.length > 0">
                            <div class="alert alert-danger">
                                {{errormsg}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            user: '',
            pwd: '',
            errormsg: ''
        }
    },
    methods: {
        // async load() {
        //     let resp = await axios.get(this.apiurl + "movies", {
        //         headers: {
        //             'Authorization': 'Bearer ' + this.token
        //         }
        //     });
        //     console.log(resp);
        // },
        async login() {
            let auth = {
                identifier: this.user,
                password: this.pwd
            }
            axios.post(this.apiurl + "auth/local", auth).then((resp) => {
                console.log(resp);

                this.$store.dispatch("token", resp.data.jwt);
                window.localStorage.setItem("token", resp.data.jwt);
                this.$router.push("/");

            }).catch((error) => {

                this.errormsg = error.response.statusText

                this.errormsg += " - " + error.response.data.error?.message;
            });

        },
        // async create() {

        //     this.data = {
        //         data: {
        //             listdata: JSON.parse(localStorage.getItem("movielist"))
        //         }
        //     };
        //     console.log(this.data);

        //     let resp = await axios.post(this.apiurl + "movies", this.data, {
        //         headers: {
        //             'Authorization': 'Bearer ' + this.token
        //         }
        //     });

        //     console.log(resp);

        // },
        // save() {

        // },

        // logout() {
        //     window.localStorage.removeItem("token");
        // }

    }
}
</script>

<style scoped>
.logincontainer {
    background-size: cover;
}

.container-wrapper {
    background-color: #fff;
    border-radius: 5px;
    padding: 1em
}
</style>
