<template>
<div class="main">
    <router-view></router-view>
</div>
<div class="loader" v-if="isLoading"><i class="fas fa-spinner fa-spin"></i></div>
</template>

<script>
export default {
    created() {
        console.log("app created");

        this.axios.interceptors.request.use((config) => {
            this.$store.dispatch('isLoading', true);
            return config;
        }, (error) => {
            this.$store.dispatch('isLoading', false);
            return Promise.reject(error);
        });

        this.axios.interceptors.response.use((response) => {
            this.$store.dispatch('isLoading', false);
            return response;
        }, (error) => {
            this.$store.dispatch('isLoading', false);
            return Promise.reject(error);
        });

    },
    computed: {
        isLoading() {
            return this.$store.getters.isLoading;
        },

    },
}
</script>

<style>

</style>
