<template>
<div class="container">
    <h1 class="mt-3 mb-3">Gespeicherte Filme</h1>

    <ul class="list">
        <li class="listitem" v-for="(elem, index) in movies" :key="index">
            <SearchResult :elem="elem" @addToList="removeFromList" icon="fa-trash" />
            <!-- <img class="listitem--img" :src="'https://image.tmdb.org/t/p/w200/' + elem.poster_path " />
                <div class="listitem--details">
                  <div class="listitem--title">{{elem.title}} </div>
                  <div class="listitem--subtitle text-muted">{{elem.release_date}}</div>
                  <p class="listitem--text">{{elem.overview}}</p>
                </div>
                <button @click="removeFromList(elem)" class="addbtn btn btn-primary"><span class="fas fa-minus"></span></button> -->
        </li>
    </ul>

</div>
</template>

<script>
import SearchResult from '@/components/SearchResult.vue'
import axios from 'axios'

export default {
    components: {
        SearchResult
    },
    data() {
        return {
            movies: []
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
          console.log("load")
            if (this.$store.getters.movieList == null) {
                let resp = await axios.get(this.apiurl + "movies", {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.getters.token
                    }
                });
                this.$store.dispatch("listID", resp.data.data[0].id);
                this.$store.dispatch("movieList", resp.data.data[0].attributes.listdata);
                this.movies = resp.data.data[0].attributes.listdata;
            }
            else{
              this.movies = this.$store.getters.movieList;
            }
            
        },
        removeFromList(elem) {
            //const index = this.movies.indexOf(elem);
            let del = this.movies.find(f => f.id == elem.id);
            console.log(this.movies.indexOf(del));
            this.movies.splice(this.movies.indexOf(del), 1);

            this.$store.dispatch("movieList", this.movies);
        }
    }

}
</script>

<style scoped>
.list {
    margin: 0;
    padding: 0;
}

.addbtn {
    align-self: center;
}

.list {
    margin: 0;
    padding: 0;
}

.listitem {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
}

.listitem--title {
    font-weight: bold;
}

.listitem--details {
    margin-left: 2em;
}

.listitem--img {
    width: 150px;
    align-self: flex-start;
}
</style>
