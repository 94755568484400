import { createRouter, createWebHistory } from 'vue-router'
import AppView from '../views/AppView.vue'
import SucheView from '../views/SucheView.vue'
import ListView from '../views/ListView.vue'
import LoginView from '../views/LoginView.vue'

import store from '../store/index'

const routes = [
  {
    path: '/',
    component: AppView,
    children:[
        {
          path: '/',
          name: 'SucheView',
          component: SucheView,
          auth: true
        },
        {
          path: '/list',
          name: 'ListView',
          component: ListView,
          auth: true
        }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.getters.isAuthenticated) 
    next({ name: 'login' })
  else next()
})

export default router
