<template>
<div class="container">
    <NavBar />
</div>
<router-view></router-view>


</template>

<script>
import NavBar from '@/components/NavBar.vue'
export default {
    components: {
        NavBar
    },
}
</script>

<style>
.loader {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    
    z-index: 10;
    position: fixed;
    display:flex;
    justify-content: center;
}

.loader>i {
    transform: translate(50%, -50%);
    top: 50%;
    position: absolute;
    font-size: 3em;
    color: #fff;
}
</style>
