<template>
  <nav>
<ul class="nav nav-pills nav-fill">
  <li class="nav-item">
    <router-link active-class="" exact-active-class="active" class="nav-link" to="/">Suche</router-link>
  </li>
  <li class="nav-item">
    <router-link active-class="" exact-active-class="active" class="nav-link" to="/list">Meine Liste</router-link>
  </li>
  <li>
  <button class="btn btn-outline-secondary" @click="logout">Logout</button>
  </li>
</ul>
  </nav>
</template>

<script>
export default {
  methods:{
    logout(){
      window.localStorage.removeItem("token");
      this.$store.dispatch("token", "");
      this.$router.push("/login");
    }
  }
}
</script>

<style scoped>
nav{
    margin-left:15px;
    margin-right:15px;
    margin-top:15px;
}
</style>

