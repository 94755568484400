<template>
<div class="container mt-5">
    <div>
        <form @submit.prevent="">
            <label>Suche</label>
            <div class="input-group ">
                <input v-model="suchtext" type="text" class="form-control" />
                <button @click="search" class="btn btn-primary">Suchen</button>
            </div>
        </form>
    </div>

    <div class="" v-if="ergebnis.length > 0">
        <h2>Suchergebnis:</h2>
        <ul class="list">
            <li class="listitem" v-for="(elem, index) in ergebnis" :key="index">
                <SearchResult :elem="elem" @addToList="addToList" icon="fa-plus" />
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import SearchResult from '@/components/SearchResult.vue'
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios'

export default {
    name: 'SucheView',
    components: {
        SearchResult
    },
    data() {
        return {
            suchtext: '',
            ergebnis: []
        }
    },
    created() {
        this.check();
    },
    methods: {
        async check() {
            if (this.$store.getters.movieList == null) {
                let resp = await axios.get(this.apiurl + "movies", {
                    headers: {
                        'Authorization': 'Bearer ' + this.$store.getters.token
                    }
                });
                console.log("RESP:", resp);

                if (resp.data.data.length == 0) { //dann ist keine liste vorhanden
                    let data = {
                        data: {
                            listdata: []
                        }
                    };

                    let resp2 = await axios.post(this.apiurl + "movies", data, {
                        headers: {
                            'Authorization': 'Bearer ' + this.$store.getters.token
                        }
                    });

                    this.$store.dispatch("listID", resp2.data.data.id);
                    this.$store.dispatch("movieList", []);
                } else {
                    this.$store.dispatch("listID", resp.data.data[0].id);
                    
                    if (!Array.isArray(resp.data.data[0].attributes.listdata))
                        this.$store.dispatch("movieList", []);
                    else
                        this.$store.dispatch("movieList", resp.data.data[0].attributes.listdata);

                    
                }
            }
        },
        async addToList(elem) {

            let list = this.$store.getters.movieList;
            list.push(elem);


            this.$store.dispatch('movieList', list);
            this.$toast.success('Hinzugefügt!');
        },
        search() {
            this.ergebnis = [];
            const params = new URLSearchParams();
            params.append('api_key', this.apikey);
            params.append('query', this.suchtext);
            params.append('language', 'de');

            this.axios.get("https://api.themoviedb.org/3/search/multi", {
                params
            }).then((response) => {
                console.log(response.data)
                this.ergebnis = response.data.results;
            })

            // https://image.tmdb.org/t/p/w200/2uNW4WbgBXL25BAbXGLnLqX71Sw.jpg
            // "https://api.themoviedb.org/3/search/keyword?api_key=<<api_key>>&page=1"
            // "https://api.themoviedb.org/3/movie/550?api_key=552a3c6430370a41d9a69b051f5d7807"

        }
    }
}
</script>

<style scoped>
.addbtn {
    align-self: center;
}

.list {
    margin: 0;
    padding: 0;
}

.listitem {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
}
</style>
