import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/css/all.css'

import axios from 'axios'
import VueAxios from 'vue-axios'
import Toaster from "@meforma/vue-toaster";


const app = createApp(App);
app.config.globalProperties.apikey = process.env.VUE_APP_MOVIEAPIKEY
app.config.globalProperties.apiurl = process.env.VUE_APP_API
console.log(process.env);
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(Toaster, { position: "top" })
app.mount('#app')
