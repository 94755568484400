<template>
<div class="listitem--wrapper">
    <img class="listitem--img" :src="'https://image.tmdb.org/t/p/w200/' + poster " />
    <div class="listitem--details">
        <div class="listitem--title">{{title}} </div>
        <div>
            <span class="listitem--subtitle text-muted">{{getDate}}</span>
            <span class="badge bg-secondary">{{type}}</span>
        </div>
        <p class="listitem--text">{{overview}}</p>
    </div>

    <div class="d-flex flex-column">
        <button @click="addToList(elem)" class="addbtn btn btn-primary mb-3"><span class="fas" :class="icon"></span></button>
        <button @click="werstreamt(elem)" class="addbtn btn btn-secondary"><span class="fas fa-search"></span></button>
    </div>
</div>
</template>

<script>
export default {
    props: ['elem', 'icon'],
    data() {
        return {
            poster: '',
            title: '',
            date: '',
            overview: '',
            type: '',

        }
    },
    watch: {
        elem() {
            this.load();
        }
    },
    mounted() {
        this.load()
    },
    methods: {
        werstreamt() {
            let utitle = encodeURIComponent(this.title);
            let a = "https://www.werstreamt.es/filme-serien/?q=" + utitle + "&action_results=suchen";
            window.location.href = a;
        },
        addToList(elem) {
            this.$emit("addToList", elem);
        },
        load() {
            if (this.elem.media_type == "movie") {
                this.poster = this.elem.poster_path;
                this.title = this.elem.title;
                this.date = this.elem.release_date;
                this.overview = this.elem.overview;
                this.type = "Film";
            } else if (this.elem.media_type == "tv") {
                this.poster = this.elem.poster_path;
                this.title = this.elem.name;
                this.date = this.elem.first_air_date;
                this.overview = this.elem.overview;
                this.type = "Serie";
            }
        }

    },
    computed: {
        getDate() {
            let dt = new Date(this.date);

            return dt.getDate() + "." + dt.getMonth() + "." + dt.getFullYear();
        }
    }

}
</script>

<style scoped>
.listitem--text {
    max-height: 200px;
    overflow: hidden;
}

.listitem--wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    width: 100%;
}

.listitem--title {
    font-weight: bold;
    font-size: 1.3em;
}

.listitem--details {
    margin-left: 2em;
    flex-grow: 1;
}

.listitem--img {
    width: 150px;
    align-self: flex-start;
}

.listitem--subtitle {
    margin-right: 0.5em;
}

.addbtn {
    align-self: flex-start;
}
</style>
