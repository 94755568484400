import {
  createStore
} from 'vuex'
import axios from 'axios';


export default createStore({
  state: {
    isLoading: false,
    movielist: null,
    listID: 0,
    isAuthenticated: false,
    token: ''
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    listID(state) {
      return state.listID;
    },
    movieList(state) {
      // if(window.localStorage.getItem("movielist") != null )
      //   state.movielist = JSON.parse(window.localStorage.getItem("movielist"));
      return state.movielist;
    },
    isAuthenticated(state) {
      if (window.localStorage.getItem("token") != null) {
        state.isAuthenticated = true;
      } else
        state.isAuthenticated = false;

      return state.isAuthenticated;
    },
    token(state) {
      if (window.localStorage.getItem("token") != null)
        state.token = window.localStorage.getItem("token");
      else
        state.token = null;

      return state.token;
    }
  },
  mutations: {
    isLoading(state, value) {
      state.isLoading = value;
    },
    listID(state, value) {
      state.listID = value;
    },
    movieList(state, value) {
      state.movielist = value;
      //window.localStorage.setItem("movielist", JSON.stringify(state.movielist));
    },
    token(state, value) {
      state.token = value;
    },
  },
  actions: {
    isLoading(context, isLoading) {
      context.commit("isLoading", isLoading);
    },
    listID(context, listID) {
      context.commit("listID", listID);
    },
    movieList(context, value) {
      //Update movie list
      let data = {
        data: {
          listdata: value
        }
      }

      return axios.put(process.env.VUE_APP_API + "movies/" + context.getters.listID, data, {
        headers: {
          'Authorization': 'Bearer ' + context.getters.token
        }
      }).then(() =>{
        
        context.commit("movieList", value);
      });

    },
    token(context, value) {
      context.commit("token", value);
    }
  }
})